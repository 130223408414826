import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product2',
  templateUrl: './product2.component.html',
  styleUrls: ['./product2.component.scss']
})
export class Product2Component implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;

  constructor(private router: Router) { }
  ngAfterViewInit(): void {
    this.videoplayer?.nativeElement.play();

  }
  navigate() {
    this.router.navigate(['IP-10S-RE'])
  }


  ngOnInit(): void {
  }

}
