import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsComponent } from '../../util/backdrop1/contact-us/contact-us.component';

@Component({
  selector: 'app-slide7',
  templateUrl: './slide7.component.html',
  styleUrls: ['./slide7.component.scss']
})
export class Slide7Component implements OnInit {
  @Input()
  index = 0;
  @Input()
  lastIndex = 0;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
  }


  openDialog() {
    const dialogRef = this.dialog.open(ContactUsComponent,{
      width:'500px',
      data:{
        model:''
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }



}
