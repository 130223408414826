<div
  style="width: 100vw; height: 100vh;  background-image: url(../../../assets/images/guitar_5.jpg);background-size: 120%; position: absolute; background-size: cover ;" class="floating3">
</div>



<div class="floating2  "
  style="background-color: rgba(0, 0, 0, 0.185); background-image: url('../../../assets/image/glare6.jpg'); filter: opacity(.7) blur(30px); width: 100vw; height: 400vh; position: absolute; z-index: 99; top: 0; left: 0; mix-blend-mode:hard-light; background-size: cover; ">

</div>

<div fxLayout="column" fxLayoutAlign="center stretch" style="max-height: 100vh; height: 100%;">

  <div fxLayout.lt-md="column" fxLayout="row"
    style=" position: absolute;  width: 100%;   height: 100%; z-index: 990; top: 0;   max-width: 100vw;   "
    fxLayoutAlign="start stretch">

    <div fxFlex=12 fxFlex.lt-md="15"   >

    </div>

    <div fxFlex fxFlex.lt-md="40"    fxLayoutAlign.lt-md="center end"
      fxLayoutAlign="center center">


      <div>
        <h1 class="cover-right cover-right-anim  delay-1" style="text-align: left; font-size: 90px; color: rgb(0, 0, 0); line-height: 100px; font-weight: bold;  font-family: copperplateBoldCD !important; font-stretch: extra-expanded;
        background-image: url(../../../assets/image/gold2.jpg);   background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; background-size: cover;
text-align: right;
        "
          ngStyle.lt-md="font-size:40px; line-height:45px; text-align:center; margin-top:50px">
          IP-10S-RE
        </h1>
        <p ngStyle.lt-md="font-size:1.1rem; line-height:1.1rem;  text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px; text-align: right !important; "
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">10" Rechargeable 300W Active 2-Way Karaoke
      </p>
        <p ngStyle.lt-md="font-size:1.1rem; line-height:1.1rem;  text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px; text-align: right !important;"
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">DJ/PA Speaker System with Bluetooth
    </p>

        <p ngStyle.lt-md="font-size:1.1rem; line-height:1.1rem;  text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px; text-align: right !important;"
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">TWS /USB SD Reader /FM /Remote /Microphone
  </p>

        <span fxLayout="row" fxLayoutAlign="end center"fxLayoutAlign.lt-md="center center">
          <button mat-stroked-button color="primary"  (click)="openDialog()"
            style="color: gold; border: goldenrod 2px solid; border-radius: 10px; width: 100%;">INQUIRE NOW</button>

        </span>
      </div>

    </div>



    <div fxFlex fxLayoutAlign="center center"  fxLayoutAlign.lt-md="start center"   fxLayout="column"   style=" ">


      <img fxFlex  src="../../../assets/image/IMG_1383.png"
        class=" animate__animated animate__zoomInRight  animate__delay-3s floating " style=" object-fit: contain;


   height:60%; width:75%;



    " ngStyle.lt-md="width:50%" ngStyle.lt-sm="width:70%">

    </div>

    <div fxFlex=12 fxFlex.lt-md="15"   style="  ">

    </div>

  </div>


</div>


