import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PageScrollService } from 'ngx-page-scroll-core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'homec';
  activeSection = 1;
  public config: SwiperConfigInterface = {
    // a11y: true,
    direction: 'vertical',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: !true,
    pagination: false
  };
  opened: boolean = false;

  @ViewChild('drawer')
  public drawer!: MatSidenav;


  index: any = 0;

  lastIndex: any = 0;
  hideSplash = false;

  newDelay = '3s';
  navShowing = false;

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    private router: Router
  ) { }
  ngOnInit(): void {

    setTimeout(() => {
      this.hideSplash = true;
    }, 4000)



    setTimeout(() => {
      this.newDelay = '0s';
    }, 4500)
  }

  fullPageScroll(e: HTMLElement, i: any) {
    this.pageScrollService.scroll({
      scrollTarget: e,
      document: this.document
    });

    this.activeSection = i;
  }


  onIndexChange($evt: any) {
    this.lastIndex = this.index;
    this.index = $evt;
    console.log($evt)
  }

  onSwiperEvent(val: string) { }


  open() {
    this.drawer!.toggle()

  }

  openCHanged() {
    this.navShowing = !this.navShowing;

  }


  goHome() {
    this.router.navigate(['home'])
    // this.router.navigate(['/home'])
  }

  goContact() {
    this.router.navigate(['contact'])

  }
  goAboutUs() {
    this.router.navigate(['who-are-we'])

  }
}
