import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-default-front-product',
  templateUrl: './default-front-product.component.html',
  styleUrls: ['./default-front-product.component.scss']
})
export class DefaultFrontProductComponent implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;
  newDelay='3s';

  constructor() { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.videoplayer?.nativeElement.play();

    }, 3000);


    setTimeout(()=>{
      this.newDelay='0s';
    },4500)
  }

  ngOnInit(): void {

  }

}
