<div
  style="width: 100vw; height: 100vh;  background-image: url(assets/images/dj_3.jpg); background-size: 120%; position: absolute; background-size: cover ;" class="floating3">
</div>


<div class="floating2  "
  style="background-color: rgba(0, 0, 0, 0); background-image: url('../../../assets/image/glare1.webp'); filter: opacity(.4) blur(0px); width: 100vw; height: 400vh; position: absolute; z-index: 99; top: 0; left: 0; mix-blend-mode:darken;  ">

</div>


<div fxLayout="column" fxLayoutAlign="center stretch" style="max-height: 100vh; height: 100%;">

  <div fxLayout.lt-md="column" fxLayout="row"
    style=" position: absolute;  width: 100%;   height: 100%; z-index: 990; top: 0;   max-width: 100vw;   "
    fxLayoutAlign="start stretch">

    <div fxFlex=12 fxFlex.lt-md="15" fxLayoutOrder="1" fxFlexOrder.lt-md="1">

    </div>

    <div fxFlex fxFlex.lt-md="40" fxFlexOrder.lt-md="2" fxFlexOrder="3" fxLayoutAlign.lt-md="center end"
      fxLayoutAlign="center center">


      <div fxFlex>
        <h1 class="cover-right cover-right-anim delay-1" style="text-align: left; font-size: 90px; color: rgb(0, 0, 0); line-height: 100px; font-weight: bold;  font-family: copperplateBoldCD !important;  font-stretch: extra-expanded;
        background-image: url(../../../assets/image/gold2.jpg);   background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; background-size: cover;"
          ngStyle.lt-md="font-size:40px; line-height:45px; text-align:center; margin-top:50px">
          IP-15CO
        </h1>
        <span ngStyle.lt-md="font-size:1.1rem; line-height:1.1rem; text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px;"
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">15-inch 2000W 2 Way Powered
        </span>
        <span ngStyle.lt-md="font-size:1.1rem; line-height:1rem; text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px;"
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">DJ/PA System Combo Set Speaker With FM
        </span>

        <span ngStyle.lt-md="font-size:1.1rem;line-height:1rem; text-align:center; "
          style="text-align: left; font-size: 1.8rem; color: white; line-height: 2rem; margin-top: 5px; margin-bottom: 5px; "
          class=" animate__animated animate__fadeIn animate__delay-4s   brandedText">Radio/ Bluetooth/ Stand/ Remote/ Microphone
        </span>

        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center">
          <button mat-stroked-button color="primary"  (click)="openDialog()"
            style="color: gold; border: goldenrod 2px solid; border-radius: 10px; width: 100%;">INQUIRE NOW</button>

        </span>
      </div>

    </div>



    <div fxFlex fxLayoutAlign.lt-md="center start" fxFlexOrder.lt-md="3" fxFlexOrder="2" fxLayoutAlign="center center">


      <img src="assets/image/IPS15CO_combo.png"
        class=" animate__animated animate__zoomInRight  animate__delay-3s floating " style=" object-fit: contain;


   height:90%; width:95%;
    object-position: start;


    " ngStyle.lt-md="width:60%" ngStyle.lt-sm="width:90%">

    </div>

    <div fxFlex=12 fxFlex.lt-md="15" fxFlexOrder="4" fxFlexOrder.lt-md="4" style=" ">

    </div>

  </div>


</div>





<!--
<video src="https://next.homec.ch/s/sWnraBNcSf6GG8k/download" preload  #videoPlayer [muted]="true" loop
  style="width: 100vw; height: 100vh; object-fit: cover; filter: saturate(150%) ; z-index: 0;">

</video> -->
