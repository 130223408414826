import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  index: any = 0;
  lastIndex: any = 0;
  activeSection = 1;
  public config: SwiperConfigInterface = {
    // a11y: true,
    direction: 'vertical',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: true,
    navigation: !true,
    pagination: false
  };

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;



  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.url.subscribe(e => {

      switch (e.toString()) {
        case 'home':
          this.index = 0
          return;
        case 'IP-15CO':
          this.index = 1
          return;
        case 'IP-10S-RE':
          this.index = 2
          return;
        case 'IP-12CO-RE':
          this.index = 3
          return;
        case 'IP-15CO-B':
          this.index = 4
          return;
        case 'IP-15CO-2.2C':
          this.index = 5
          return;
          case 'who-are-we':
            this.index = 6
            return;
          case 'contact':
            this.index = 7
            return;
      }
    })

  }

  onIndexChange($evt: any) {
    this.lastIndex = this.index;
    this.index = $evt;
    console.log($evt)


    switch (this.index) {
      case 0:
        this.router.navigateByUrl('home')
        return;
      case 1:
        this.router.navigateByUrl('IP-15CO')


        return;
      case 2:
        this.router.navigateByUrl('IP-10S-RE')


        return;
      case 3:
        this.router.navigateByUrl('IP-12CO-RE')


        return;
      case 4:
        this.router.navigateByUrl('IP-15CO-B')


        return;
      case 5:

        this.router.navigateByUrl('IP-15CO-2.2C')

        return;
      case 6:

        this.router.navigateByUrl('who-are-we')

        return;
      case 7:

        this.router.navigateByUrl('contact')


        return;

    }
  }

  onSwiperEvent(val: string) { }

}
