<app-item-select [pagePos]="pagePos" (newItemEvent)="changeModel($event)" ></app-item-select>

<div style="position: relative; max-height: 100vh; max-width: 100vw; min-height: 100vh; overflow: none;">

  <div [ngSwitch]="pagePos" fxFlex>

    <app-default-front-product *ngSwitchCase=" 0" fxFlex></app-default-front-product>
    <app-product1 class=" animate__animated animate__fadeIn" fxFlex *ngSwitchCase=" 1"></app-product1>
    <app-product2  class=" animate__animated animate__fadeIn" f fxFlex *ngSwitchCase=" 2"></app-product2>
    <app-product3  class=" animate__animated animate__fadeIn" f fxFlex *ngSwitchCase=" 3"></app-product3>
    <app-product4   class=" animate__animated animate__fadeIn" f fxFlex *ngSwitchCase=" 4"></app-product4>
    <app-product5   class=" animate__animated animate__fadeIn" f fxFlex *ngSwitchCase=" 5"></app-product5>
  </div>

</div>



