import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product4',
  templateUrl: './product4.component.html',
  styleUrls: ['./product4.component.scss']
})
export class Product4Component implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;

  constructor(private router: Router) { }
  ngAfterViewInit(): void {
    this.videoplayer?.nativeElement.play();

  }
  navigate() {
    this.router.navigate(['IP-15CO-B'])
  }


  ngOnInit(): void {
  }

}
