<h1 mat-dialog-title class="title">WRITE TO US</h1>



<div mat-dialog-content>
  <div   fxLayout="column" fxLayoutAlign="center stretch" style="width: 100%;  " fxFlex>

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Tell Us Your Name</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Give Us Your Email to Reach You Back</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Tell Us Your Message</mat-label>
      <textarea matInput></textarea>
    </mat-form-field>

  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button   mat-dialog-close  >CANCEL</button>
  <button mat-button   cdkFocusInitial style="color:gold !important; width:100px;">SEND</button>
</div>
