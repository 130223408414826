<swiper *ngIf="!false" class="swiper-container" #swiper style="width: 100vw; z-index: 100; " [config]="config"
  (indexChange)="onIndexChange($event)" [index]="index" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
  effect="cube" (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">

  <div class="swiper-slide">
    <app-slide1 [index]="index" [lastIndex]="lastIndex" style=" top: 0; bottom: 0; right: 0; left: 0;"></app-slide1>
  </div>


  <div class="swiper-slide">

    <app-slide2 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide2>
  </div>


  <div class="swiper-slide">

    <app-slide3 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide3>
  </div>


  <div class="swiper-slide">

    <app-slide4 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide4>
  </div>



  <div class="swiper-slide">

    <app-slide5 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide5>
  </div>



  <div class="swiper-slide">

    <app-slide6 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide6>
  </div>




  <div class="swiper-slide">

    <app-slide7 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide7>
  </div>




  <div class="swiper-slide">

    <app-slide8 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
    </app-slide8>
  </div>





</swiper>
