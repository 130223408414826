import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { Slide1Component } from './home/slide1/slide1.component';
import { Slide2Component } from './home/slide2/slide2.component';
import { Slide3Component } from './home/slide3/slide3.component';
import { Slide4Component } from './home/slide4/slide4.component';
import { ParallaxDirective } from './services/parallax.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SvgDrawingComponent } from './home/slide1/svg-drawing/svg-drawing.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { ThrottleSplashScreenService } from './services/splash-screen.service';
import { Backdrop1Component } from './util/backdrop1/backdrop1.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import { Slide5Component } from './home/slide5/slide5.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import { Slide6Component } from './home/slide6/slide6.component';
import { Slide7Component } from './home/slide7/slide7.component';
import { Slide8Component } from './home/slide8/slide8.component';
import { Product1Component } from './home/slide1/product1/product1.component';
import { Product2Component } from './home/slide1/product2/product2.component';
import { Product3Component } from './home/slide1/product3/product3.component';
import { Product4Component } from './home/slide1/product4/product4.component';
import { Product5Component } from './home/slide1/product5/product5.component';
import { Product6Component } from './home/slide1/product6/product6.component';
import { Product7Component } from './home/slide1/product7/product7.component';
import { DefaultFrontProductComponent } from './home/slide1/default-front-product/default-front-product.component';
import { ItemSelectComponent } from './home/slide1/item-select/item-select.component';
import { HomeComponent } from './home/home/home.component';
import { ContactUsComponent } from './util/backdrop1/contact-us/contact-us.component';
import { ProductInquiryComponent } from './util/backdrop1/product-inquiry/product-inquiry.component';

import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {MatInputModule} from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {ClipboardModule} from '@angular/cdk/clipboard';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  speed:1500
};



@NgModule({
  declarations: [
    AppComponent,
    Slide1Component,
    Slide2Component,
    Slide3Component,
    Slide4Component,
    ParallaxDirective,
    SvgDrawingComponent,
    SideNavBarComponent,
    Backdrop1Component,
    Slide5Component,
    Slide6Component,
    Slide7Component,
    Slide8Component,
    Product1Component,
    Product2Component,
    Product3Component,
    Product4Component,
    Product5Component,
    Product6Component,
    Product7Component,
    DefaultFrontProductComponent,
    ItemSelectComponent,
    HomeComponent,
    ContactUsComponent,
    ProductInquiryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPageScrollCoreModule.forRoot({ duration: 1600 }),
    SwiperModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    MatSnackBarModule,
    MatIconModule,
    ClipboardModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    ThrottleSplashScreenService

  ],
  entryComponents:[],

  bootstrap: [AppComponent]
})
export class AppModule { }
