import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent implements OnInit {
  @Input()
  pagePos = 0;


  @Output() newItemEvent = new EventEmitter<number>();


  constructor() { }

  ngOnInit(): void {
  }


  changeModel(model: number) {
    this.newItemEvent.emit(model)
  }

}
