import { HttpClient, HttpRequest } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  model: '';
}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  data = {
    model: '',
    email: '',
    name: '',
    phone: '',
    message: ''
  }
  constructor(private http: HttpClient,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ContactUsComponent>,
    @Inject(MAT_DIALOG_DATA) public dData: DialogData) {
    this.data.model = dData.model;
  }

  ngOnInit(): void {
  }


  sendEmail() {
    const d = new FormData();
    d.set('email', this.data.email);
    d.set('name', this.data.name);
    d.set('message', this.data.model+'\n'+this.data.phone + '\n' + this.data.message);

    this.http.post<HttpRequest<string>>('https://ipointsolutionssl.com/assets/php/email.php', d, { observe: 'response' }).subscribe((e) => {

      if (e.status === 200) {
        this.dialogRef.close();
        this._snackBar.open("We will get back to you soon", "OK");
      }
    },()=>{
      this.dialogRef.close();
      this._snackBar.open("We will get back to you soon", "OK");
    });
    // this.dialogRef.close();

  }

}
