import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product5',
  templateUrl: './product5.component.html',
  styleUrls: ['./product5.component.scss']
})
export class Product5Component implements OnInit, AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;

  constructor(private router: Router) { }
  ngAfterViewInit(): void {
    this.videoplayer?.nativeElement.play();

  }
  navigate() {
    this.router.navigate(['IP-15CO-2.2C'])
  }


  ngOnInit(): void {
  }

}
