<div
  style="background-color: rgba(0, 0, 0, 0);  width: 100vw; height: 100vh; z-index: 9999999; overflow: hidden;  position: fixed;">


  <!-- <img src="../../../assets/image/b2.png"> -->

  <!-- <img src="../../../assets/image/b1.png" style="background-repeat: repeat-x; position: absolute; bottom: -10px; width: 100%; background-size: contain; height: 30vh;" > -->

  <!-- <div style="">

    <div class="slideRightReveal"
      style="background-color: rgb(0, 0, 0); position:  absolute; width: 100%; height: 100%; transform: rotate(45deg) translate(0%,-99%) scale(2);    ">

    </div>
  </div>


  <div class=" ">

    <div class=" slideLeftReveal"
      style="background-color: rgb(0, 0, 0); position:  absolute; width: 100%; height: 100%; transform: rotate(45deg) translate(0%,99%)  scale(2);  ">

    </div>
  </div> -->




<!--
<div style="width: 100vw; height: 100vh; background-color: black;">

</div> -->


  <!-- left -->

  <div style="position: relative; height: 100vh; width: 100%; background-color: rgba(0, 0, 0, 0);    "
    class=" animate__animated    animate__slideOutLeft animate__delay-3s ">
    <div class="triangle-topright  "
      style="width: 101vw; height: 101vh; background-color: rgb(0, 0, 0); position: absolute;">

    </div>
  </div>


  <!-- right -->

  <div class="triangle-bottomleft animate__animated    animate__slideOutRight animate__delay-3s"
    style="width: 101vw; height: 101vh; background-color: rgb(0, 0, 0); position: absolute; top: 0; right: 0;   ">

  </div>



  <!--



 -->


  <!-- <div style="background-image: url(assets/image/1.webp); width:100%; height:100vh; object-fit: cover; background-repeat: no-repeat; position: absolute; background-size: cover; mix-blend-mode: color-burn;">

</div> -->




<div style="position: absolute; bottom: -30px;  width: 100%; height: 100vh" fxLayout="column" fxLayoutAlign="end stretch">


  <div  class="animate__animated animate__delay-3s animate__slideOutDown" style="z-index: 9999;">
    <div   class="animate__animated animate__bounceInUp  " style="background-image: url(../../../assets/image/wave_grid2.png);  width: 100vw; height: 100vh; opacity: .4; background-repeat: no-repeat; background-position: bottom; " >

    </div>

  </div>

  <!-- <div   style="background-image: url(../../../assets/image/WhichChiefHammerheadbird-max-1mb.gif);  position: absolute; z-index: 1; width: 100%; height: 30vh;" >

  </div> -->


</div>

<!--

  <div style="position: absolute; bottom: -10px;  width: 100%; height: 100%; "
    class="animate__animated animate__delay-3s  animate__slideOutDown">
    <div class="animate__animated animate__bounceInUp"
      style="background-image: url(../../../assets/image/WhichChiefHammerheadbird-max-1mb.gif); position: absolute; bottom: -10px; background-repeat: repeat-x; width: 100%; height: 100%; background-position: bottom; background-size: 50%; opacity: .2; mix-blend-mode: screen;"
      ngStyle.lt-md=" background-size:contain; background-size:400%;"
      >

    </div>
  </div>



  <div style="position: absolute; bottom: -10px; width: 100%; height: 100%;"
    ngStyle.lt-md=" background-size:contain; bottom:0px"
    class="animate__animated animate__delay-3s animate__slideOutDown">
    <div class="animate__animated animate__bounceInUp animate__delay-1s"
      style="background-image: url(../../../assets/image/grid_neon2.png);  background-repeat: no-repeat; width: 100%; height: 100%; background-position: bottom; background-size: 100%;  --animate-delay: 1s; filter: sepia(33%) ; opacity: .4;"
      ngStyle.lt-md=" background-size:contain; background-size:400%;">

    </div>

  </div> -->

</div>

<div style="width: 100%; height: 100%; position: absolute;  --animate-delay: 2.5s !important; z-index: 9999999; "
  class="animate__animated   animate__zoomIn  " fxLayout="row" fxLayoutAlign="center center">

  <div fxLayout=column class="animate__animated animate__fadeOut  animate__delay-1s" style="z-index: 9999999;">
    <img fxFlex src="../../../assets/logo/ips.png"
      style="width: 350px; height: 300px;   background-position: center;  object-fit: contain; z-index: 9999999;" />

  </div>
</div>
