import { ElementRef, ViewChild } from '@angular/core';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
declare var VanillaTilt: any;

@Component({
  selector: 'app-slide1',
  templateUrl: './slide1.component.html',
  styleUrls: ['./slide1.component.scss']
})
export class Slide1Component implements OnInit, AfterViewInit {
  @Input()
  index = 0;
  @Input()
  lastIndex = 0;

  slide1 = true;
  slide2 = false;
  slide3 = false;
  slide4 = false;
  slide5 = false;
  slide6 = false;
  slide7 = false;

  pagePos = 0;
  source = interval(1000 * 10);
  interval: any;
  constructor() { }
  ngAfterViewInit(): void {



    // VanillaTilt.init(document.querySelector(".animcard"), {
    //   max: 50,
    //   speed: 300,
    //   glare: true,
    //   "max-glare": 0.5,
    // });

    this.runInterval();

    // setInterval(() => {
    //     this.pagePos++

    //     if (this.pagePos == 6) this.pagePos = 0;
    //   }, 1000 * 10)

  }

  changeModel(pos: any) {
    this.interval.unsubscribe();
    this.pagePos = pos+1;
    this.runInterval();

  }

  ngOnInit(): void {

  }


  runInterval() {

    this.interval = this.source.subscribe(() => {
      this.pagePos++

      if (this.pagePos === 6) this.pagePos = 0;
    })
  }

}
