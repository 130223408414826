import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactUsComponent } from 'src/app/util/backdrop1/contact-us/contact-us.component';

@Component({
  selector: 'app-slide8',
  templateUrl: './slide8.component.html',
  styleUrls: ['./slide8.component.scss'],
})
export class Slide8Component implements OnInit {
  @Input()
  index = 0;
  @Input()
  lastIndex = 0;

  constructor(public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private clipboard:Clipboard
    ) {}

  ngOnInit(): void {}

  openDialog() {
    const dialogRef = this.dialog.open(ContactUsComponent, {
      width: '500px',
      data: {
        model: '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  copy(address: number) {
    this._snackBar.open("Address Copied to Clipboard", "OK");

    switch (address) {
      case 1:
        this.clipboard.copy("  Ipoint Solutions (PVT) LTD, \nNo 42, Agaradaguru Mawatha,\n2nd Lane, Thudalla, Ja-Ela\nSri Lanka\n\n+94 0112290286\ninfo@ipointsolutionssl.com   ")
        return;
      case 2:
        this.clipboard.copy(" Ipoint Solutions (PVT) LTD\nNo:140A, Minuwangoda Road,\n Kanuwana ,Ja-Ela,\nSri Lanka\n\n+94 773482670\n info@ipointsolutionssl.com    ")

        return;
      case 3:
        this.clipboard.copy(" ABEYSINGHE ELECTRONICS\nNO 84, NEGOMBO ROAD,\nPANNALA,\nSri Lanka\n\n+94 776227962\n+94 374983913\nabesingha5@gmail.com ")

        return;
      case 4:
        this.clipboard.copy("IPOINT SOLUTIONS Co LTD\nROOM 2104, 21/F, K. WAH CENTRE,\n191 JAVA ROAD, NORT POINT,\nHONG KONG\n\n+852 34834566\ninfo@ipointsolutionshk.com     ")

        return;
    }


  }
}
