<div style="width: 150px; height: 100%; background-color: rgba(255, 255, 255, 0); z-index: 9999;" fxFlex
    fxLayout="column" fxLayoutAlign="space-arround center">
    <span fxFlex></span>
    <span fxFlex></span>

    <p><span class="material-icons animate__bounceInRight animate__animated" style="animation-delay: 0.1s;">
        adjust
        </span>
    </p>
    <hr fxFlex>
    <p><span class="material-icons animate__bounceInRight animate__animated"  style="animation-delay: 0.4s;">
            fiber_manual_record
        </span>
    </p>
    <hr fxFlex>
    <p><span class="material-icons animate__bounceInRight animate__animated"  style="animation-delay: 0.7s;">
            fiber_manual_record
        </span>
    </p>
    <hr fxFlex>
    <p><span class="material-icons animate__bounceInRight animate__animated"  style="animation-delay: 1.1s;">
            fiber_manual_record
        </span>
    </p>
    <hr fxFlex>
    <p><span class="material-icons animate__bounceInRight animate__animated"  style="animation-delay: 1.4s;">
            fiber_manual_record
        </span>
    </p>

    <span fxFlex></span>
    <span fxFlex></span>
</div>