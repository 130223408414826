<div
  fxShow
  fxHide.lt-lg=""
  style="background-color: black; width: 100vw; flex: 0 0 auto"
  *ngIf="!false"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div
    fxFlex
    fxHide.lt-md=""
    fxShow
    style="
      background-image: url(../../../assets/image/glare4.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      width: 100vw;
      filter: blur(10px);
      position: absolute;
    "
  ></div>
  <div
    fxFlex
    fxShow.lt-md=" "
    fxHide
    style="
      background-image: url(../../../assets/image/glare7.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      width: 100vw;
      filter: blur(5px);
      position: absolute;
    "
  ></div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    style="
      z-index: 9999;
      padding-top: 68px;
      padding-left: 120px;
      padding-right: 120px;
    "
    ngStyle.lt-md="padding-left: 20px; padding-right: 20px; font-size:2px;"
  >
    <h1
      style="
        font-family: copperplateBoldCD;
        color: goldenrod;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover;
        background-image: url(../../../assets/image/gold2.jpg);
        margin: 0;
      "
    >
      ꧁ Contact Us ꧂
    </h1>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start stretch"
    style="
      width: 100%;
      z-index: 9999;
      padding-left: 120px;
      padding-right: 120px;
    "
    ngStyle.lt-md="padding-left: 16px;padding-right: 16px;"
    fxLayoutGap="30px"
  >
    <div
      fxLayout="column"
      fxLayout.lt-md="column"
      fxFlex
      class="addressCard"
      fxLayoutGap="10px"
    >
      <span fxLayout="column" fxLayoutAlign="center center">
        <button
          mat-stroked-button
          color="primary"
          (click)="openDialog()"
          style="
            color: gold;
            border: goldenrod 2px solid;
            border-radius: 10px;
            width: 100%;
          "
        >
          WRITE TO US
        </button>
        <br />
      </span>

      <div fxLayoutGap="10px" fxLayout="row">
        <div
          fxFlex
          fxFlex.lt-md="100"
          fxFlexOrder.lt-md="2"
          style="margin: 0 !important"
        >
          <div class="mapouter" fxFlex>
            <div class="gmap_canvas" style="border-radius: 20px" fxFlex>
              <iframe
                style="
                  width: 100%;
                  height: 100%;
                  border-radius: 20px;
                  display: flex;
                  flex: 1 1 0 auto;
                "
                fxFlex
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=ipoint%20solutions&t=&z=18&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              >
              </iframe>
            </div>
          </div>
        </div>

        <div
          fxFlex="50"
          fxFlex.lt-md="100"
          fxFlexOrder.lt-md="1"
          fxLayout="column"
          fxLayout.lt-md="column"
          fxLayoutGap="15px"
          fxLayoutAlign="center stretch"
        >
          <div
            fxLayout="row"

            fxFlex
            fxLayoutAlign="space-between start;"
            fxLayoutGap="20px"
          >
            <div style="width: 300px; padding-left: 20px">
              <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(1)"  class="adTitle">
                Sri Lanka Company &nbsp;&nbsp;<mat-icon  style="font-size: 20px;" >content_copy</mat-icon>

              </h1>
              <p
                class="address"
                ngStyle.gt-md="font-size:15px; line-height:20px;"
              >
                Ipoint Solutions (PVT) LTD<br />
                No 42, Agaradaguru Mawatha,<br />
                2nd Lane, Thudalla, Ja-Ela<br />
                Sri Lanka<br />
                <a href="tel:+94113466488">+94 0113466488</a> <br />
                <a href="mailto:info@ipointsolutionssl.com"
                  >info@ipointsolutionssl.com</a
                >
              </p>
            </div>

            <div style="width: 300px; padding-left: 20px">
              <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(2)"  class="adTitle">
                Main Showroom&nbsp;&nbsp;<mat-icon  style="font-size: 20px;" >content_copy</mat-icon>
              </h1>
              <p
                class="address"
                ngStyle.gt-md="font-size:15px; line-height:20px;"
              >
                Ipoint Solutions (PVT) LTD<br />
                No:140A, Minuwangoda Road,<br />
                Kanuwana ,Ja-Ela<br />
                Sri Lanka<br />
                <a href="tel:+94773482670">+94 773482670</a><br />
                <a href="mailto:info@ipointsolutionssl.com"
                  >info@ipointsolutionssl.com</a
                >
              </p>
            </div>
          </div>
          <div
            fxLayout="row"

            fxFlex
            fxLayoutAlign="space-between start;"
            fxLayoutGap="20px"
          >
            <!-- <div style="width: 300px">
              <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(3)"  class="adTitle">
                Store Locators&nbsp;&nbsp;<mat-icon  style="font-size: 20px;" >content_copy</mat-icon>
              </h1>
              <p
                class="address"
                ngStyle.gt-md="font-size:15px; line-height:20px;"
              >
              IPOINT SOLUTIONS  (PVT) LTD<br />
                No.79,<br />
                Mirigama Road,Divulapitiya<br />
                Sri Lanka<br />
                &nbsp;
                <a href="tel:+94314368060">031- 436 80 60</a>,<br />
                <a href="mailto:info@ipointsolutionshk.com"
                >info@ipointsolutionshk.com</a  >
              </p>
            </div> -->

            <div style="width: 300px; padding-left: 20px">
              <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(4)"  class="adTitle">
                Hong Kong Company&nbsp;&nbsp;<mat-icon  style="font-size: 20px;" >content_copy</mat-icon>
              </h1>
              <p
                class="address"
                ngStyle.gt-md="font-size:15px; line-height:20px;"
              >
                IPOINT SOLUTIONS Co LTD<br />
                ROOM 2104, 21/F, K. WAH CENTRE,<br />
                191 JAVA ROAD, NORT POINT,<br />
                HONG KONG<br />
                <a href="tel:+85234834566">+852 34834566</a><br />
                <a href="mailto:info@ipointsolutionshk.com"
                  >info@ipointsolutionshk.com</a  >
               <br>
               <br>
              </p>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        fxFlexOrder="4"
      >
        <p class="followus" style="color: white; margin: 0">Follow us on :</p>

        <a
          class="sicon fb"
          fxLayoutAlign="center center"
          href="https://www.facebook.com/ipointsolutionssl/"
        >
          <img src="https://img.icons8.com/color/30/000000/facebook-new.png" />
        </a>
        <a
          class="sicon insta"
          fxLayoutAlign="center center"
          href="https://www.instagram.com/ipointsolutionssl/"
        >
          <img
            src="https://img.icons8.com/color/30/000000/instagram-new--v1.png"
          />
        </a>
      </div>
    </div>
  </div>

  <div
    style="
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 36px;
      background-color: black;
      z-index: 999;
    "
    ngStyle.lt-md=" height: 36px;"
  >
    <p style="color: white; font-size: 12px">
      Designed and developed with ❤️ by
      <a href="https://theheavenscode.com">Heaven'sCode</a>
    </p>
  </div>
</div>

<div
  fxShow
  fxHide.gt-lg=""
  fxHide.lg
  style="background-color: black; width: 100vw; flex: 0 0 auto"
  *ngIf="!false"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div
    fxFlex
    fxHide.lt-md=""
    fxShow
    style="
      background-image: url(../../../assets/image/glare4.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      width: 100vw;
      filter: blur(10px);
      position: absolute;
    "
  ></div>
  <div
    fxFlex
    fxShow.lt-md=" "
    fxHide
    style="
      background-image: url(../../../assets/image/glare7.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      width: 100vw;
      filter: blur(5px);
      position: absolute;
    "
  ></div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    style="
      z-index: 9999;
      padding-top: 68px;
      padding-left: 120px;
      padding-right: 120px;
    "
    ngStyle.lt-md="padding-left: 20px; padding-right: 20px; font-size:2px;"
  >
    <h1
      style="
        font-family: copperplateBoldCD;
        color: goldenrod;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover;
        background-image: url(../../../assets/image/gold2.jpg);
        margin: 0;
      "
    >
      ꧁ Contact Us ꧂
    </h1>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start stretch"
    style="
      width: 100%;
      z-index: 9999;
      padding-left: 120px;
      padding-right: 120px;
    "
    ngStyle.lt-md="padding-left: 16px;padding-right: 16px;"
    fxLayoutGap="30px"
  >
    <div
      fxLayout="column"
      fxLayout.lt-md="column"
      fxFlex
      class="addressCard"
      fxLayoutGap="10px"
    >
      <span fxLayout="column" fxLayoutAlign="center center">
        <button
          mat-stroked-button
          color="primary"
          (click)="openDialog()"
          style="
            color: gold;
            border: goldenrod 2px solid;
            border-radius: 10px;
            width: 100%;
          "
        >
          WRITE TO US
        </button>
      </span>
      <div
        fxFlex
        fxFlex.lt-md="100"
        fxFlexOrder.lt-md="2"
        style="margin: 0 !important"
      >
        <div class="mapouter">
          <div class="gmap_canvas" style="border-radius: 20px">
            <iframe
              style="width: 100%; height: 120px; border-radius: 20px"
              id="gmap_canvas"
              ngStyle.gt-md="height:200px"
              ngStyle.gt-lg="height:300px"
              src="https://maps.google.com/maps?q=ipoint%20solutions&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            >
            </iframe>
          </div>
        </div>
      </div>

      <div
        fxFlex="40"
        fxFlex.lt-md="100"
        fxFlexOrder.lt-md="1"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="5px"
        fxLayoutAlign="center stretch"
      >
        <div
          fxLayout="row"
          fxLayoutGap="5px"
          fxFlex
          fxLayoutAlign="center start"
        >
          <div fxFlex>
            <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(1)"  class="adTitle">
              Sri Lanka Company &nbsp;&nbsp;<mat-icon  style="font-size: 14px;" >content_copy</mat-icon>
            </h1>
            <p
              class="address"
              ngStyle.gt-md="font-size:15px; line-height:20px;"
            >
              Ipoint Solutions (PVT) LTD<br />
              No 42, Agaradaguru Mawatha,<br />
              2nd Lane, Thudalla, Ja-Ela<br />
              Sri Lanka<br />
              <a href="tel:+94113466488">+94 0113466488</a> <br />
              <a href="mailto:info@ipointsolutionssl.com"
                >info@ipointsolutionssl.com</a
              >
            </p>
          </div>

          <div fxFlex>
            <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(2)"   class="adTitle">
              Main Showroom &nbsp;&nbsp;<mat-icon  style="font-size: 14px;" >content_copy</mat-icon>
            </h1>
            <p
              class="address"
              ngStyle.gt-md="font-size:15px; line-height:20px;"
            >
              Ipoint Solutions (PVT) LTD<br />
              No:140A, Minuwangoda Road,<br />
              Kanuwana ,Ja-Ela<br />
              Sri Lanka<br />
              &nbsp;
              <a href="tel:+94773482670">+94 773482670</a><br />
              <a href="mailto:info@ipointsolutionssl.com"
                >info@ipointsolutionssl.com</a
              >
            </p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxFlex>
          <!-- <div fxFlex>
            <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(3)"  class="adTitle">
              Store locators &nbsp;&nbsp;<mat-icon  style="font-size: 14px;" >content_copy</mat-icon>
            </h1>
            <p
              class="address"
              ngStyle.gt-md="font-size:15px; line-height:20px;"
            >
            IPOINT SOLUTIONS  (PVT) LTD<br />
            No.79, Mirigama Road,<br />
            Divulapitiya<br />
              Sri Lanka<br />
              <br>
              <a href="tel:+94314368060">031- 436 80 60</a>,<br />
              <a href="mailto:info@ipointsolutionshk.com"
              >info@ipointsolutionshk.com</a  >
            </p>
          </div> -->

          <div fxFlex>
            <h1 ngClass.lt-md="mobileAddressTitle" (click)="copy(4)"   class="adTitle">
              Hong Kong Company &nbsp;&nbsp;<mat-icon  style="font-size: 14px;" >content_copy</mat-icon>
            </h1>
            <p
              class="address"
              ngStyle.gt-md="font-size:15px; line-height:20px;"
            >
              IPOINT SOLUTIONS Co LTD<br />
              ROOM 2104, 21/F, K. WAH CENTRE,<br />
              191 JAVA ROAD, NORT POINT,<br />
              HONG KONG<br />
              <a href="tel:+85234834566">+852 34834566</a><br />
              <a href="mailto:info@ipointsolutionshk.com"
                >info@ipointsolutionshk.com</a
              >
            </p>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        fxFlexOrder="4"
      >
        <p class="followus" style="color: white; margin: 0">Follow us on :</p>

        <a
          class="sicon fb"
          fxLayoutAlign="center center"
          href="https://www.facebook.com/ipointsolutionssl/"
        >
          <img src="https://img.icons8.com/color/30/000000/facebook-new.png" />
        </a>
        <a
          class="sicon insta"
          fxLayoutAlign="center center"
          href="https://www.instagram.com/ipointsolutionssl/"
        >
          <img
            src="https://img.icons8.com/color/30/000000/instagram-new--v1.png"
          />
        </a>
      </div>
    </div>
  </div>

  <div
    style="
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 36px;
      background-color: black;
      z-index: 999;
    "
    ngStyle.lt-md=" height: 36px;"
  >
    <p style="color: white; font-size: 12px">
      Designed and developed with ❤️ by
      <a href="https://theheavenscode.com">Heaven'sCode</a>
    </p>
  </div>
</div>
