import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product3',
  templateUrl: './product3.component.html',
  styleUrls: ['./product3.component.scss']
})
export class Product3Component implements OnInit , AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;

  constructor(private router: Router) { }
  ngAfterViewInit(): void {
    this.videoplayer?.nativeElement.play();

  }
  navigate() {
    this.router.navigate(['IP-12CO-RE'])
  }


  ngOnInit(): void {
  }

}
