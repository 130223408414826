

<div
style="width: 100vw; height: 100vh;  background-image: url(../../../assets/images/dj_1.jpg);background-size: 120%; position: absolute; background-size: cover ;" class="floating3">
</div>


<div class="floating2  "
style="background-color: rgba(0, 0, 0, 0.445); background-image: url('../../../assets/image/glare9.webp'); filter: opacity(.5) blur(0px); width: 100vw; height: 400vh; position: absolute; z-index: 99; top: 0; left: 0; mix-blend-mode: hard-light;  ">

</div>



<div
  style="background-color: rgba(0, 0, 0, 0.397); width: 100vw; height: 100%; max-height: 100%; padding-top: 66px; z-index: 999999999; position: absolute; top:0; "
  fxLayout="row" fxLayout.lt-md="column"
  fxLayoutAlign="center stretch"   fxLayoutAlign.lt-md="start stretch"
>
<div fxFlex="10" fxHide.lt-md=""></div>


<div fxFlex
    fxLayout="column"
    fxLayoutAlign="start start"
    style="
      z-index: 9999;
      /* padding-top: 68px; */
      padding-left: 120px;
      padding-right: 120px;
    "
    ngStyle.lt-md="padding-left: 20px; padding-right: 20px; font-size:2px;"
  >
    <h1
      style="
        font-family: copperplateBoldCD;
        color: goldenrod;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover;
        background-image: url(../../../assets/image/gold2.jpg);

        margin: 0;
        margin-bottom: 10px;
      "
    >
      ꧁ Who Are We ꧂
    </h1>
    <h2 style="text-align: left; width: 100%">
      Thank you for visiting IPoint Solutions
    </h2>
    <p class="aboutUs" ngStyle.lt-md="font-size:15px; line-height:19px">
      Ipoint Solutions (PVT) Ltd is one of leading home electronics products
      company head office located in Hong Kong and branch office in Sri Lanka.
      Our strategy is to build products that sound great and easy to use with
      the latest technology.
    </p>
    <p class="aboutUs" ngStyle.lt-md="font-size:15px; line-height:19px">
      We hope to hear from you and get feedback about our products and service,
      please feel free to reach us from any of below contact details. Or please
      visit our stores located in Sri Lanka or in Hong Kong for the best
      undserstating and expirincing the quality and power of our speakers.<br /><br />
    </p>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlexOrder="4">
      <p class="followus" style="color: white; margin: 0;">Follow us on :</p>

      <a class="sicon fb" fxLayoutAlign="center center" href="https://www.facebook.com/ipointsolutionssl/">
        <img src="https://img.icons8.com/color/30/000000/facebook-new.png" />
      </a>
      <a class="sicon insta" fxLayoutAlign="center center" href="https://www.instagram.com/ipointsolutionssl/">
        <img src="https://img.icons8.com/color/30/000000/instagram-new--v1.png" />
      </a>
    </div>
  </div>


  <div fxFlex="40" >
    <div fxLayout="center stretch"  fxLayout.lt-md="center stretch"  fxLayout="column">
      <img fxFlex
      src="assets/images/about_us_mobile.jpg"
      src.lt-md="assets/images/about_us_mobile.jpg"
      class="animate__animated animate__zoomInRight animate__delay-3s"
      style="
        object-fit: contain;
        height: 100%;
background-color: aqua;
        width: 100%;
        margin: 20px;
      "
      ngStyle.lt-md="width:60%"
      ngStyle.lt-sm="width:90%"
    />


    </div>
  </div>

  <div fxFlex="10"></div>
</div>
