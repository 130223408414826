<div fxHide.lt-md="" fxLayout="column"
  style="width: 400px; height: 100vh; position: absolute;   z-index: 999999999999; right: 0;">
  <div fxFlex>

  </div>





  <div fxLayout="row" class="title " fxFlex *ngFor="let item of ['IP-15CO','IP-10S-RE','IP-12CO-RE','IP-15CO-B','IP-15CO-2.2C']; let i=index;" (click)="changeModel(i)"  [ngClass]="{titleSelected:pagePos===i+1}">
    <div fxFlex fxLayout="column" fxLayoutAlign="center end" >
      <span class="model   "  >{{item}}</span>
    </div>
    <div fxFlex="25" fxLayout="column" fxLayoutAlign="center center">

      <div fxLayout="column" fxLayoutAlign="center center" class="bulletCover">
        <span class="bullet">

        </span>
      </div>


    </div>

  </div>









  <div fxFlex>

  </div>

</div>
