<div style="position: absolute; height: 100vh; width: 100vw; overflow: hidden;  ">
  <video playsinline src="../../../../assets/video/produced/ips1.mp4" preload #videoPlayer [muted]="true" loop
    style="  min-height: 100vh; object-fit: cover;   z-index: 99990; width: 100%; height: 100%; ">

  </video>



</div>

<div
  style="background-color: rgba(0, 0, 0, 0.288); width: 100vw; height: 100vh; position: absolute; z-index: 99; top: 0; left: 0; overflow: hidden; ">

</div>


<div fxLayout="column" fxLayoutAlign="center stretch" style="max-height: 100vh; height: 100%; overflow: hidden; ">

  <div fxLayout.lt-md="column" fxLayout="row"
    style=" position: absolute;  width: 100%;   height: 100%; max-height: 100vh; z-index: 990; top: 0;   max-width: 100vw;  "
    fxLayoutAlign="start stretch">

    <div fxFlex=15 fxFlex.lt-md="6">

    </div>
    <div fxFlex fxFlex.lt-md="30" fxLayout="column" fxLayoutAlign="center center" style="    width: 100%;" ngStyle.lt-md="padding-left:30px; padding-right:30px;">
      <div>
        <h1 class="cover-right cover-right-anim delay-1 brandedTextHeadingCD  " style="text-align: left; font-size: 90px; color: rgb(0, 0, 0); line-height: 100px;
 font-family: copperplateBoldCD !important;
        font-stretch: extra-expanded; font-weight: bolder;
      background-image: url(assets/image/gold2.jpg);   background-repeat: no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; background-size: cover;"
          ngStyle.lt-md="font-size:40px; line-height:45px; text-align:center; margin-top:10px">
          IPOINT SOLUTIONS
        </h1>
        <span ngStyle.lt-md="font-size:24px; line-height:25px; text-align:center; "
          style="text-align: left; font-size: 45px; color: white; line-height: 52px; margin-top: 5px; margin-bottom: 5px;"
          class="animate__animated animate__fadeIn animate__delay-4s  delay-1 brandedText">Premium Speakers For
        </span>
        <span ngStyle.lt-md="font-size:24px; line-height:25px; text-align:center; "
          style="text-align: left; font-size: 45px; color: white; line-height: 52px; margin-top: 5px; margin-bottom: 5px;"
          class="  animate__animated animate__fadeIn animate__delay-4s  delay-1  brandedText">Home & Outdoor
        </span>
      </div>

    </div>

    <div fxFlex class="floating" style="width: 100%;  ">


      <img src="assets/image/ips product legend.png" *ngIf="false"
        class=" animate__animated animate__zoomInRight  animate__delay-3s  " style=" object-fit: contain;


   height:100%; width:95%


    " ngStyle.lt-md="width:90%" ngStyle.lt-sm="width:100%">

    </div>
    <div fxFlex=15 fxFlex.lt-md="15">

    </div>
  </div>

  <div *ngIf="false" fxLayout="row" fxFlex
    style="height: 100px; background-color: rgb(255, 72, 0); width: 100%; z-index: 9999;">
    <div class="mouse_scroll">

      <div class="mouse">
        <div class="wheel"></div>
      </div>
      <div>
        <span class="m_scroll_arrows unu"></span>
        <span class="m_scroll_arrows doi"></span>
        <span class="m_scroll_arrows trei"></span>
      </div>
    </div>

  </div>

</div>



<!--
<video src="https://next.homec.ch/s/sWnraBNcSf6GG8k/download" preload  #videoPlayer [muted]="true" loop
  style="width: 100vw; height: 100vh; object-fit: cover; filter: saturate(150%) ; z-index: 0;">

</video> -->

<div style="overflow: hidden;">
  <div
  style="position: absolute; top: 56px; left: 0; right: 0; width: 100%; background-color: rgb(0, 0, 0); height: 15px; z-index: 99999;">
  <div fxLayout="row" fxFlex>
    <div style="  height: 100%; left: 0;" class="widthAnimClass">

    </div>
    <img src="../../../../assets/image/glimmergif1.gif" style="height: 100%; width: 40px;   ">


  </div>

</div>
</div>

