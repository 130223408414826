import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product6',
  templateUrl: './product6.component.html',
  styleUrls: ['./product6.component.scss']
})
export class Product6Component implements OnInit , AfterViewInit {
  @ViewChild('videoPlayer') videoplayer?: ElementRef;

  constructor(private router: Router) { }
  ngAfterViewInit(): void {
    this.videoplayer?.nativeElement.play();

  }
  navigate() {
    this.router.navigate(['IP-15CO'])
  }


  ngOnInit(): void {
  }

}
