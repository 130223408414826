<!-- <small style="color: white; position: absolute; bottom: 0; left: 0;"> {{index}} - {{lastIndex}} </small> -->

<mat-drawer-container class="example-container" style="width: 100vw; height: 100%; overflow: hidden;" fxFlex fxFill [hasBackdrop]="true">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" (openedChange)="openCHanged()"
    style="width: 300px; background-color: rgba(0, 0, 0, 0.671); border-bottom-left-radius: 20px; border-top-left-radius: 20px;">





    <div fxFlex fxLayout=column fxLayoutGap="45px" fxLayoutAlign="center center" *ngIf="navShowing"
      style="margin-top: -56px;">

      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="nav-link mob animate__animated animate__bounceIn" style="font-family: copperplateBoldCD;" (click)="goHome()"
          *ngIf="navShowing">HOME</p>
      </div>



      <!-- <div fxLayout="column" fxLayoutAlign="center center">
        <p class="nav-link mob animate__animated animate__bounceIn "  style="  --animate-duration: 0.5s;  --animate-delay: 0.5s;">PRODUCTS</p>
      </div>

 -->
      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="nav-link mob animate__animated animate__bounceIn" (click)="goAboutUs()" style="  --animate-duration: 0.8s;  --animate-delay: 0.8s; font-family: copperplateBoldCD;">WHO ARE WE </p>
      </div>



      <div fxLayout="column" fxLayoutAlign="center center">
        <p class="nav-link mob animate__animated  animate__bounceIn" (click)="goContact()"
          style="  --animate-duration: 1.3s;  --animate-delay: 1.5s; font-family: copperplateBoldCD;">CONTACT US</p>
      </div>


      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlexOrder="4">
        <p class="followus" style="color: white; font-size: 15px; font-family: minionProCD;">Follow us on :</p>
        <div fxLayout="row" fxLayoutGap="15px">

          <a class="sicon fb" fxLayoutAlign="center center" href="https://www.facebook.com/ipointsolutionssl/">
            <img src="https://img.icons8.com/color/30/000000/facebook-new.png" />
          </a>
          <a class="sicon insta" fxLayoutAlign="center center" href="https://www.instagram.com/ipointsolutionssl/">
            <img src="https://img.icons8.com/color/30/000000/instagram-new--v1.png" />
          </a>
        </div>

      </div>


    </div>

  


  </mat-drawer>
  <mat-drawer-content style="overflow-x: none;">

    <div
      style="position: absolute; height: 60px; background-color: rgb(0, 0, 0); width: 100%; top: 0; z-index:9999999 ; backdrop-filter: blur(15px); top: 0; width: 100%;"
      fxLayout=row>
      <div fxFlex=5>

      </div>
      <div style=" height: 60px; padding-top: 2px; background-image: url(/assets/logo/ips.png);" routerLink="home">
        <img src="../assets/logo/ips.png" style="height: 60px; width: 100px;  object-fit: contain;  ">

      </div>

      <div fxFlex fxLayout=row fxLayoutGap="15px" fxLayoutAlign="end center" fxHide.lt-md="" fxShow=""
        style="font-family: minionProCD;">

        <div fxLayout="column" fxLayoutAlign="center center" (click)="goHome()">
          <p class="nav-link" style="font-family: copperplateBoldCD; font-size: 20px; font-weight: bold; cursor: pointer;">
            HOME</p>
        </div>


        <div fxLayout="column" fxLayoutAlign="center center" (click)="goAboutUs()">
          <p class="nav-link" style="font-family: copperplateBoldCD; font-size: 20px; font-weight: bold; cursor: pointer;">
            WHO ARE WE </p>
        </div>

 




        <div fxLayout="column" fxLayoutAlign="center center" (click)="goContact()">
          <p class="nav-link" style="font-family: copperplateBoldCDF; font-size: 20px; font-weight: bold; cursor: pointer;">
            CONTACT US


          </p>
        </div>


        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlexOrder="4">
          <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
            <!-- <p class="followus" style="color: white; font-size: 15px;">Follow us on :</p> -->

            <a class="sicon fb" fxLayoutAlign="center center" href="https://www.facebook.com/ipointsolutionssl/">
              <img src="https://img.icons8.com/color/30/000000/facebook-new.png" />
            </a>
            <a class="sicon insta" fxLayoutAlign="center center" href="https://www.instagram.com/ipointsolutionssl/">
              <img src="https://img.icons8.com/color/30/000000/instagram-new--v1.png" />
            </a>
          </div>

        </div>


      </div>

      <div fxShow.lt-md fxFlex fxLayout="row" fxLayoutAlign="end center" fxHide style="padding-top:15px;">
        <button (click)="open()" mat-button style=" ">
          <!-- <mat-icon aria-hidden="false" aria-label="Example home icon" class="nav-link"
            style="font-size: 25px !important; font-family: inherit !important;">menu</mat-icon> -->
          <p>

            <i class="fas fa-bars nav-link " style="font-size: 25px;"></i>
          </p>
        </button>


      </div>

      <div fxFlex=5>

      </div>
    </div>



    <!-- <swiper *ngIf="!false" class="swiper-container" style="width: 100vw; z-index: 100;" [config]="config"
      (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
      (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">

      <div class="swiper-slide">
        <app-slide1 [index]="index" [lastIndex]="lastIndex" style=" top: 0; bottom: 0; right: 0; left: 0;"></app-slide1>
      </div>


      <div class="swiper-slide">

        <app-slide2 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide2>
      </div>


      <div class="swiper-slide">

        <app-slide3 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide3>
      </div>


      <div class="swiper-slide">

        <app-slide4 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide4>
      </div>



      <div class="swiper-slide">

        <app-slide5 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide5>
      </div>



      <div class="swiper-slide">

        <app-slide6 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide6>
      </div>




      <div class="swiper-slide">

        <app-slide7 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide7>
      </div>




      <div class="swiper-slide">

        <app-slide8 [index]="index" [lastIndex]="lastIndex" style="  top: 0; bottom: 0; right: 0; left: 0;">
        </app-slide8>
      </div>





    </swiper>
 -->




    <!-- <div fxLayout="row" style=" position: absolute;  width: 100%;   height: 100%; z-index: 99; display: block; overflow: hidden;" focusable="false"
    fxLayoutAlign="center center">

    <img fxShow.md fxHide.gt-md class="animate__animated animate__delay-3s  animate__backInRight animate__heartBeat     "
       [ngStyle]=" {'animation-delay':index!==0?'0s':newDelay}"
      src="../assets/ss/Simulator Screen Shot - iPhone 12 Pro Max - 2021-03-26 at 12.06.16_iphone12black_portrait.png"
      style="width: 250px; height: 780px; object-fit: contain; ">




  </div> -->

    <!-- [class.scaleDownMoveToCorner]="index===1" -->
    <!-- <div fxLayout="row" style=" width: 100%; height: 100vh; position: absolute;   " fxLayoutAlign="center center "
      *ngIf="false">
      <div fxFlex="30">

      </div>

      <div fxFlex>

        <div class=" "
          style=" display: flex; justify-content:center; vertical-align: middle;   align-items: center; background-color: rgb(255, 255, 255);">


          <div style="position: absolute;  " class="floating">
            <img fxFlex fxShow.lg fxHide.gt-lg class="animate__animated animate__delay-3s          "
              [class.animate__backInRight]="index===0 && lastIndex===0"
              [class.animate__heartBeat]="index===0 && lastIndex===0"
              [class.scaleDownMoveToCornerReset]="index===0 && lastIndex===1" [class.scaleDownMoveToCorner]="index===1"
              [class.resetScaleDownMoveToCorner4]="index===1 && lastIndex===2"
              [class.resetScaleDownMoveToCorner1]="index===2" [class.resetScaleDownMoveToCorner2]="index===3"
              [class.resetScaleDownMoveToCorner3]="index===4" [class.animate__jackInTheBox]="index===4"
              [ngStyle]=" {'animation-delay':index!==0?'0s':newDelay}"
              src="../assets/ss/Simulator Screen Shot - iPhone 12 Pro Max - 2021-03-26 at 12.06.16_iphone12black_portrait.png"
              style="
  width: 350px;

  object-fit: contain;
  z-index: 99999;
  ">
          </div>



        </div>


      </div>


    </div>

 -->






    <!--

    <div class=" " *ngIf="false"
      style="position: relative; width: 100%; height: 100vh; position: absolute; display: flex; justify-content:center; vertical-align: middle;   align-items: center; background-color: rgb(255, 255, 255);">


      <div style="position: absolute;  " class="floating">
        <img fxFlex fxShow.lg fxHide.gt-lg class="animate__animated animate__delay-3s          "
          [class.animate__backInRight]="index===0 && lastIndex===0"
          [class.animate__heartBeat]="index===0 && lastIndex===0"
          [class.scaleDownMoveToCornerReset]="index===0 && lastIndex===1" [class.scaleDownMoveToCorner]="index===1"
          [class.resetScaleDownMoveToCorner4]="index===1 && lastIndex===2"
          [class.resetScaleDownMoveToCorner1]="index===2" [class.resetScaleDownMoveToCorner2]="index===3"
          [class.resetScaleDownMoveToCorner3]="index===4" [class.animate__jackInTheBox]="index===4"
          [ngStyle]=" {'animation-delay':index!==0?'0s':newDelay}"
          src="../assets/ss/Simulator Screen Shot - iPhone 12 Pro Max - 2021-03-26 at 12.06.16_iphone12black_portrait.png"
          style="
  width: 350px;

  object-fit: contain;
  z-index: 99999;
  ">
      </div>



    </div>
 -->



 <app-backdrop1 *ngIf="!hideSplash" style="z-index: 99999999999999;"></app-backdrop1>


    <router-outlet></router-outlet>



  </mat-drawer-content>
</mat-drawer-container>


<!-- <div style="display: none;">
  <video preload="auto" src="../assets/frontPage/desktop/1.mp4" autoplay></video>
  <video preload="auto" src="../assets/frontPage/desktop/2.mp4" autoplay></video>
  <video preload="auto" src="../assets/frontPage/desktop/3.mp4" autoplay></video>
  <video preload="auto" src="../assets/frontPage/desktop/4.mp4" autoplay></video>
  <video preload="auto" src="../assets/frontPage/desktop/5.mp4" autoplay></video>
</div> -->
